import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Placeholder from '@atlassian/jira-placeholder';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { MenuProps } from './main';
import type CardActionsMenu from './main';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCardActionsMenu = lazy<typeof CardActionsMenu>(
	() => import(/* webpackChunkName: "async-jwm-board-card-actions-menu" */ './main'),
	{ ssr: false },
);

const AsyncCardActionsMenu = (props: MenuProps) => (
	<Placeholder
		name="card-actions-menu"
		fallback={
			<SpinnerWrapper>
				<Spinner size="medium" />
			</SpinnerWrapper>
		}
	>
		<LazyCardActionsMenu {...props} />
	</Placeholder>
);

export default AsyncCardActionsMenu;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	boxSizing: 'border-box',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '220px',
	padding: token('space.400', '24px'),
});
