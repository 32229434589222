import React, { memo, useCallback, useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { AvatarLite } from '@atlassian/jira-business-avatar-lite';
import CategoryTag from '@atlassian/jira-business-categories/src/ui/category-form/category-tag/index.tsx';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import {
	statusCategoryForId,
	StatusCategoryIds,
} from '@atlassian/jira-common-constants/src/status-categories';
import { type MessageDescriptor, useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { NULL_GROUP_KEY, COLUMN_ACTION_RENAME_EXPERIENCE } from '../../../../../common/constants';
import type { Group } from '../../../../../common/types';
import { useBoardData } from '../../../../../controllers/board-data';
import { useIsWorkflowOperationInProgress } from '../../../../../controllers/workflow-actions';
import { useRenameStatus } from '../../../../../controllers/workflow-actions/rename-column';
import { useWorkflowStoreState } from '../../../../../controllers/workflow-store';
import DoneColumnTick from './done-column-tick';
import EditableColumnHeader from './editable-column-header';
import IssueCount from './issue-count';
import messages from './messages';

export type Props = {
	group: Group;
	isCollapsed: boolean;
	issueCount: number;
	isEditMode: boolean;
	setEditMode: (isEditMode: boolean) => void;
	hasMultipleWorkflows: boolean;
	hasExceededMaxIssueTypes: boolean;
};

const HeaderContent = ({
	group,
	isCollapsed,
	issueCount,
	isEditMode,
	setEditMode,
	hasMultipleWorkflows,
	hasExceededMaxIssueTypes,
}: Props) => {
	const project = useProject();
	const [headerName, setHeaderName] = useState<string | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();
	const isTeamManaged = project.isSimplified;
	const isProjectAdmin = project.permissions.administerProject;

	const { renameStatus, validateRenameStatus } = useRenameStatus(COLUMN_ACTION_RENAME_EXPERIENCE);

	useEffect(() => {
		setHeaderName(group.name);
	}, [group.name]);

	const getColumnName = useCallback(
		(message: MessageDescriptor): string =>
			group.id === NULL_GROUP_KEY ? formatMessage(message) : group.name,
		[formatMessage, group.id, group.name],
	);

	const headerContent = (
		<>
			{
				{
					[GROUP_BY_STATUS]: (
						<WritingModeWrapper isVerticalWritingMode={isCollapsed}>
							{group.type === GROUP_BY_STATUS && (
								<>
									{isVisualRefreshEnabled() ? (
										<Box xcss={statusHeaderStyles}>{headerName ?? group.name}</Box>
									) : (
										<StatusLozenge
											name={headerName ?? group.name}
											category={statusCategoryForId(group.statusCategoryId)}
										/>
									)}

									{group.statusCategoryId === StatusCategoryIds.done &&
										isVisualRefreshEnabled() && <DoneColumnTick />}
								</>
							)}
						</WritingModeWrapper>
					),
					[GROUP_BY_PRIORITY]: (
						<>
							{group.type === GROUP_BY_PRIORITY && group.imageUrl && (
								<Image alt="" draggable={false} src={group.imageUrl} />
							)}
							<ColumnName isVerticalWritingMode={isCollapsed}>
								{getColumnName(messages.noPriority)}
							</ColumnName>
						</>
					),
					[GROUP_BY_ASSIGNEE]: (
						<>
							{group.id === NULL_GROUP_KEY ? (
								<Avatar appearance="circle" src="" size="small" />
							) : (
								group.type === GROUP_BY_ASSIGNEE && (
									<AvatarLite avatarUrl={group.imageUrl ?? ''} name="" />
								)
							)}
							<ColumnName isVerticalWritingMode={isCollapsed}>
								{getColumnName(messages.noAssignee)}
							</ColumnName>
						</>
					),
					[GROUP_BY_CATEGORY]: (
						<>
							{group.type === GROUP_BY_CATEGORY && (
								<WritingModeWrapper isVerticalWritingMode={isCollapsed}>
									<CategoryTag
										categoryName={getColumnName(messages.noCategory)}
										categoryColor={group.color}
									/>
								</WritingModeWrapper>
							)}
						</>
					),
				}[group.type]
			}
			<IssueCount isCollapsed={isCollapsed} issueCount={issueCount} />
		</>
	);

	const shouldRenderReadOnly =
		!isTeamManaged ||
		!isProjectAdmin ||
		hasMultipleWorkflows ||
		group.type !== GROUP_BY_STATUS ||
		isWorkflowOperationInProgress ||
		hasExceededMaxIssueTypes;

	const { refetch: refetchBoardData } = useBoardData();

	const { columns } = useWorkflowStoreState();

	const {
		data: { issueTypes },
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const issueTypeIdNotSubtask = issueTypes.find((issueType) => issueType.hierarchyLevel !== -1)?.id;

	return shouldRenderReadOnly || isCollapsed ? (
		<HeaderContentWrapper isColumnCollapsed={isCollapsed}>{headerContent}</HeaderContentWrapper>
	) : (
		<EditableColumnHeaderWrapper>
			<EditableColumnHeader
				defaultValue={group.name}
				isEditing={isEditMode}
				readView={() => <HeaderContentWrapper>{headerContent}</HeaderContentWrapper>}
				onCancel={() => setEditMode(false)}
				onConfirm={async (newName) => {
					setEditMode(false);
					setHeaderName(newName);
					try {
						await renameStatus({
							oldName: group.name,
							newName,
							statusId: Number(group.id),
							issueTypeIdNotSubtask,
						});
					} catch {
						// Errors are handled in the controller
					} finally {
						refetchBoardData();
					}
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'columnRenameInlineEdit confirmed',
						'jwmBoardColumnRename',
					);
				}}
				onEdit={() => {
					setEditMode(true);

					fireUIAnalytics(
						createAnalyticsEvent({}),
						'columnHeader clicked',
						'jwmBoardColumnRenameFromTitle',
						{
							isProjectAdmin,
							hasMultipleWorkflows,
							hasExceededMaxIssueTypes,
						},
					);
				}}
				onValidate={(newName) =>
					validateRenameStatus({
						oldName: group.name,
						newName,
						existingStatusNames: columns.map((c) => c.name),
					})
				}
			/>
		</EditableColumnHeaderWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WritingModeWrapper = styled.div<{ isVerticalWritingMode: boolean }>({
	display: 'flex',
	minWidth: 0,
	minHeight: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	writingMode: ({ isVerticalWritingMode }) => (isVerticalWritingMode ? 'vertical-lr' : undefined),
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContentWrapper = styled.div<{
	isColumnCollapsed?: boolean;
	showDisabledCursor?: boolean;
}>({
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ showDisabledCursor }) => (showDisabledCursor ? 'not-allowed' : 'inherit'),
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isColumnCollapsed }) => (isColumnCollapsed ? 'column' : 'row'),
	flexGrow: 1,
	gap: token('space.100', '8px'),
	minWidth: 0,
	minHeight: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isColumnCollapsed }) =>
		isColumnCollapsed ? `${token('space.050', '4px')} 0px ${token('space.100', '8px')}` : '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditableColumnHeaderWrapper = styled.div({
	alignItems: 'center',
	display: 'flex',
	flexGrow: 1,
	minWidth: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	form: {
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			marginTop: '0',
		},
		/** @see: FUN-738
		 * For keyboard focus handling; overrides extra offset when focusing on Atlaskit's InlineEdit button
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'button:focus': {
			outline: 'none',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	width: token('space.200', '16px'),
	height: token('space.200', '16px'),
	verticalAlign: 'text-bottom',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnName = styled.div<{
	isVerticalWritingMode: boolean;
}>({
	fontWeight: 600,
	minWidth: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	writingMode: ({ isVerticalWritingMode }) => (isVerticalWritingMode ? 'vertical-lr' : undefined),
});

const statusHeaderStyles = xcss({
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
	textTransform: 'uppercase',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

export default memo(HeaderContent);
