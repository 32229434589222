import React from 'react';
import ResolutionsIcon from '@atlaskit/icon/core/check-mark';
import ResolutionsIconLegacy from '@atlaskit/icon/glyph/editor/done';
import { RESOLUTION_TYPE } from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getResolutionIcons: GetIconFunction = (size) => ({
	[RESOLUTION_TYPE]: (
		<ResolutionsIcon
			color="currentColor"
			LEGACY_size={size}
			LEGACY_fallbackIcon={ResolutionsIconLegacy}
			label=""
		/>
	),
});
