import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	exceededIssueTypesBoardActionsDisabled: {
		id: 'work-management-board.common.open-project-settings-exceeded-issue-types.exceeded-issue-types-board-actions-disabled',
		defaultMessage:
			"Looks like you've got too many issue types. You can {linkStart}add, delete, or rename columns in project settings{linkEnd}.",
		description:
			'Message shown when the project has more than the maximum issue type count, so it needs to be edited through project settings',
	},
	exceededIssueTypesBoardActionsDisabledIssueTermRefresh: {
		id: 'work-management-board.common.open-project-settings-exceeded-issue-types.exceeded-issue-types-board-actions-disabled-issue-term-refresh',
		defaultMessage:
			"Looks like you've got too many issue types. You can {linkStart}add, delete, or rename columns in project settings{linkEnd}.",
		description:
			'Message shown when the project has more than the maximum issue type count, so it needs to be edited through project settings',
	},
});
