import React from 'react';
import LozengeIcon from '@atlaskit/icon-lab/core/lozenge';
import StatusIconLegacy from '@atlaskit/icon/glyph/status';
import { STATUS_CATEGORY_TYPE } from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getStatusCategoryIcons: GetIconFunction = (size) => ({
	[STATUS_CATEGORY_TYPE]: (
		<LozengeIcon
			color="currentColor"
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={StatusIconLegacy}
		/>
	),
});
