import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createSuccessFlagTitle: {
		id: 'business-issue-create.controllers.show-create-success-flag.create-success-flag-title',
		defaultMessage: 'You\'ve created issue "{issueKey}"',
		description: 'Title for success flag when an issue is successfully created.',
	},
	viewIssueLinkText: {
		id: 'business-issue-create.controllers.show-create-success-flag.view-issue-link-text',
		defaultMessage: 'View issue',
		description: 'CTA for success flag when issue is created to navigate to the issue view.',
	},
	createSuccessFlagTitleIssueTermRefresh: {
		id: 'business-issue-create.controllers.show-create-success-flag.create-success-flag-title-issue-term-refresh',
		defaultMessage: 'You\'ve created issue "{issueKey}"',
		description: 'Title for success flag when an issue is successfully created.',
	},
	viewIssueLinkTextIssueTermRefresh: {
		id: 'business-issue-create.controllers.show-create-success-flag.view-issue-link-text-issue-term-refresh',
		defaultMessage: 'View issue',
		description: 'CTA for success flag when issue is created to navigate to the issue view.',
	},
});
