import React from 'react';
import TeamsIcon from '@atlaskit/icon/core/teams';
import TeamsIconLegacy from '@atlaskit/icon/glyph/teams';
import { TEAMS_PLATFORM_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getTeamIcons: GetIconFunction = (size) => ({
	[TEAMS_PLATFORM_CF_TYPE]: (
		<TeamsIcon
			color="currentColor"
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={TeamsIconLegacy}
		/>
	),
});
