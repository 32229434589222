import React from 'react';
import RadioCheckedIcon from '@atlaskit/icon/core/radio-checked';
import RadioIcon from '@atlaskit/icon/glyph/radio';
import { RADIO_BUTTONS_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getRadioSelectIcon: GetIconFunction = (size) => ({
	[RADIO_BUTTONS_CF_TYPE]: (
		<RadioCheckedIcon
			color="currentColor"
			label=""
			LEGACY_size={size}
			LEGACY_fallbackIcon={RadioIcon}
		/>
	),
});
