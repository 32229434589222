import React, { memo, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIconOld from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	useFetchUserTraitsOld,
	useUserTraits,
} from '@atlassian/jira-business-common/src/common/providers/controllers/traits';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ModalContextProvider } from '@atlassian/jira-modal-context-provider';
import { createTraitsSelector } from '@atlassian/jira-traits/src/TraitsStore.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import type { BoardIssue } from '../../../../../common/types';
import { useIssueGroup } from '../../../../../controllers/issues-by-group';
import CardActionsMenu from './card-actions-menu';
import messages from './messages';

export const CARD_ACTIONS_DISPLAY_VARIABLE = '--card-actions-display';

type Props = {
	issue: BoardIssue;
};

const returnNull = () => null;

const useIsCardCoversNew = createTraitsSelector(
	'SITE_USER',
	({ userCreatedAfter, getTraitByName }) =>
		getTraitByName('jira-core_ui_cardcovereditor_clicked_first_time') === null &&
		!userCreatedAfter(new Date('2024-06-17')),
);

const CardActions = ({ issue }: Props) => {
	const { formatMessage } = useIntl();

	const [isOpen, setIsOpen] = useState(false);
	const openMenu = useCallback(() => setIsOpen(true), []);
	const closeMenu = useCallback(() => setIsOpen(false), []);
	const toggleMenu = useCallback(() => setIsOpen((open) => !open), []);
	const [isCardCoversNew] = useIsCardCoversNew();

	const { group } = useIssueGroup();

	const isCardCoversChangeboardingEnabled = fg('jwm_card_covers_changeboarding');

	const [userTraits, { setUserTraits }] = !isCardCoversChangeboardingEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useUserTraits()
		: [null, { setUserTraits: () => undefined }];
	const fetchUserTraits = !isCardCoversChangeboardingEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useFetchUserTraitsOld()
		: returnNull;

	const setUserTraitsIfNotAvailable = useCallback(async () => {
		if (isCardCoversChangeboardingEnabled) return false;

		if (!userTraits?.attributes?.length && userTraits?.attributes !== null) {
			const newTraits = await fetchUserTraits();
			if (newTraits !== false) {
				setUserTraits(newTraits);
			}
		}
	}, [userTraits, fetchUserTraits, setUserTraits, isCardCoversChangeboardingEnabled]);

	const popupContent = useCallback(
		({ update }: { update: () => void }) => (
			<CardActionsMenu
				issue={issue}
				onContentResize={update}
				onClosePopup={closeMenu}
				onMenuRendered={update}
				onOpenPopup={openMenu}
			/>
		),
		[issue, closeMenu, openMenu],
	);

	const trigger = useCallback(
		(triggerProps: TriggerProps) => {
			return isVisualRefreshEnabled() ? (
				<Box xcss={isOpen ? [IconButtonStyles, IconButtonMenuOpenStyles] : IconButtonStyles}>
					<IconButton
						{...triggerProps}
						icon={ShowMoreHorizontalIcon}
						label={formatMessage(messages.cardActionsWithIssueName, {
							columnName: group.name,
							issueKey: issue.fields.issuekey.value,
						})}
						onClick={toggleMenu}
						testId="work-management-board.ui.board.column.card.card-actions.trigger"
						onMouseOver={setUserTraitsIfNotAvailable}
						onFocus={setUserTraitsIfNotAvailable}
					/>
				</Box>
			) : (
				<TriggerButtonWrapper>
					<StyledButton
						{...triggerProps}
						iconBefore={
							<ShowMoreHorizontalIconOld
								label={formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.cardActionsWithIssueNameIssueTermRefresh
										: messages.cardActionsWithIssueName,
									{
										columnName: group.name,
										issueKey: issue.fields.issuekey.value,
									},
								)}
								color={token('color.icon')}
							/>
						}
						onClick={toggleMenu}
						testId="work-management-board.ui.board.column.card.card-actions.trigger"
						onMouseOver={setUserTraitsIfNotAvailable}
						onFocus={setUserTraitsIfNotAvailable}
						isCardCoversNew={isCardCoversNew}
					/>
				</TriggerButtonWrapper>
			);
		},
		[isOpen, formatMessage, group, issue, toggleMenu, setUserTraitsIfNotAvailable, isCardCoversNew],
	);

	return (
		<ModalContextProvider>
			<Popup
				isOpen={isOpen}
				onClose={closeMenu}
				content={popupContent}
				placement="right-start"
				trigger={trigger}
			/>
		</ModalContextProvider>
	);
};

export default memo(CardActions);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerButtonWrapper = styled.div({
	position: 'absolute',
	top: token('space.100', '8px'),
	right: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const StyledButton = styled(Button)<{ isCardCoversNew: boolean }>({
	display: `var(${CARD_ACTIONS_DISPLAY_VARIABLE}, none)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	boxShadow: (props) =>
		props.isCardCoversNew ? `0 0 0 2px ${token('color.border.discovery')}` : 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:focus, &[aria-expanded="true"]': {
		display: 'flex',
	},
});

const IconButtonStyles = xcss({
	position: 'absolute',
	top: token('space.100', '8px'),
	right: token('space.100', '8px'),
	display: `var(${CARD_ACTIONS_DISPLAY_VARIABLE}, none)`,
});

const IconButtonMenuOpenStyles = xcss({
	display: 'block',
});
