import React from 'react';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import PeopleGroupIconLegacy from '@atlaskit/icon/glyph/people-group';
import { MULTI_GROUP_CF_TYPE, GROUP_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getGroupIcons: GetIconFunction = (size) => ({
	[GROUP_CF_TYPE]: (
		<PeopleGroupIcon
			color="currentColor"
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={PeopleGroupIconLegacy}
		/>
	),
	[MULTI_GROUP_CF_TYPE]: (
		<PeopleGroupIcon
			color="currentColor"
			LEGACY_size={size}
			label=""
			LEGACY_fallbackIcon={PeopleGroupIconLegacy}
		/>
	),
});
