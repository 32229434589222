import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	doneTickTooltip: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'work-management-board.ui.board.column.column-header.header-content.done-column-tick.done-column-tick.done-tick-tooltip',
		defaultMessage: 'Issues moved to this column are marked as done',
		description:
			'Done tick icon. Shows that the column is a done column. Special visual treatment for the done column in the header.',
	},
	doneTickTooltipIssueTermRefresh: {
		id: 'work-management-board.board.column.column-header.header-content.done-column-tick.done-tick-tooltip-issue-term-refresh',
		defaultMessage: 'Issues moved to this column are marked as done',
		description:
			'Done tick icon. Shows that the column is a done column. Special visual treatment for the done column in the header.',
	},
});
