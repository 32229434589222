import React from 'react';
import Placeholder from '@atlassian/jira-placeholder';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyIllustration = lazyForPaint(
	() => import(/* webpackChunkName: "async-jwm-board-illustration" */ './main'),
);

export const Illustration = () => (
	<Placeholder name="illustration" fallback={null}>
		<LazyIllustration />
	</Placeholder>
);
