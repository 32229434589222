import React from 'react';
import EditorBulletListIcon from '@atlaskit/icon/core/bulleted-list';
import EditorBulletListIconLegacy from '@atlaskit/icon/glyph/editor/bullet-list';
import { CASCADING_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getCascadingSelectIcons: GetIconFunction = (size) => ({
	[CASCADING_SELECT_CF_TYPE]: (
		<EditorBulletListIcon
			label=""
			color="currentColor"
			LEGACY_fallbackIcon={EditorBulletListIconLegacy}
			LEGACY_size={size}
		/>
	),
});
