import React, { useState, useCallback, useMemo, memo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/add';
import EditorAddIconLegacy from '@atlaskit/icon/glyph/editor/add';
import InlineDialog from '@atlaskit/inline-dialog';
import { N10 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { createColumnEntryPoint } from '@atlassian/jira-business-create-status-modal/entrypoint';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories';
import type { RuntimePropsOfEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointIconButtonTrigger } from '@atlassian/jira-modal-entry-point-icon-button-trigger';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	MAX_ISSUE_TYPES_COUNT,
	COLUMN_ACTION_ADD_EXPERIENCE,
	TEMPORARY_STATUS_ID,
} from '../../../common/constants';
import { ContactAdminToModifyBoardMessage } from '../../../common/ui/contact-admin-to-modify-board';
import { OpenProjectSettingsExceededIssueTypes } from '../../../common/ui/open-project-settings-exceeded-issue-types';
import { OpenProjectSettingsMultipleWorkflows } from '../../../common/ui/open-project-settings-multiple-workflows';
import { useBoardData } from '../../../controllers/board-data';
import { useGroups } from '../../../controllers/groups-context';
import { useIsWorkflowOperationInProgress } from '../../../controllers/workflow-actions';
import { useCreateStatus } from '../../../controllers/workflow-actions/add-column';
import {
	useWorkflowStoreState,
	useWorkflowStoreActions,
} from '../../../controllers/workflow-store';
import messages from './messages';

type CreateStatusModalProps = RuntimePropsOfEntryPoint<typeof createColumnEntryPoint>;

const ColumnCreate = () => {
	const { workflows } = useWorkflowStoreState();
	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();

	const groups = useGroups();
	const existingColumnNames = useMemo(() => groups.map(({ name }) => name), [groups]);

	const { formatMessage } = useIntl();

	const [isNoPermissionMessageOpen, setIsNoPermissionMessageOpen] = useState<boolean | undefined>();
	const [isMultipleWorkflowsMessageOpen, setIsMultipleWorkflowsMessageOpen] = useState<
		boolean | undefined
	>();
	const [isExceededIssueTypesMessageOpen, setIsExceededIssueTypesMessageOpen] = useState<
		boolean | undefined
	>();

	const {
		data: { issueTypes },
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const issueTypeId = issueTypes.find((issueType) => issueType.hierarchyLevel !== -1)?.id;

	const createNewStatus = useCreateStatus(COLUMN_ACTION_ADD_EXPERIENCE);

	const { removeColumn } = useWorkflowStoreActions();

	const memoizedRemoveColumn = useCallback(() => removeColumn(TEMPORARY_STATUS_ID), [removeColumn]);

	const { refetch: refetchBoardData } = useBoardData();

	const project = useProject();
	const canAdministerProject = project.permissions.administerProject;
	const hasMultipleWorkflows = workflows.length > 1;
	const hasExceededMaxIssueTypes = issueTypes.length > MAX_ISSUE_TYPES_COUNT;

	const validateStatus: CreateStatusModalProps['validateStatus'] = useCallback(
		({ statusName }) =>
			!existingColumnNames.some(
				(existingColumnName) => existingColumnName.toUpperCase() === statusName.toUpperCase(),
			),
		[existingColumnNames],
	);

	const createNewColumn = useCallback(
		async ({
			statusName,
			statusCategory,
			issueTypeIdNotSubtask,
		}: {
			statusName: string;
			statusCategory: StatusCategory;
			issueTypeIdNotSubtask?: string | undefined;
		}) => {
			try {
				await createNewStatus({ statusName, statusCategory, issueTypeIdNotSubtask });
			} catch (error) {
				memoizedRemoveColumn();
				throw error;
			} finally {
				refetchBoardData();
			}
		},
		[createNewStatus, memoizedRemoveColumn, refetchBoardData],
	);

	const createColumnModalProps = useMemo(() => {
		return {
			createStatus: createNewColumn,
			validateStatus,
			issueTypeIdNotSubtask: issueTypeId,
		};
	}, [createNewColumn, issueTypeId, validateStatus]);

	if (!canAdministerProject) {
		// show button that will trigger no permission message for user without permission
		return renderColumnCreateButtonWithMessage({
			isOpen: Boolean(isNoPermissionMessageOpen),
			setIsOpen: setIsNoPermissionMessageOpen,
			testId: 'work-management-board.ui.board.column-create.no-permission-button',
			label: formatMessage(messages.createStatusLabel),
			analyticsId: 'jwmColumnCreateNoPermission',
			MessageComponent: ContactAdminToModifyBoardMessage,
		});
	}

	if (hasExceededMaxIssueTypes) {
		return renderColumnCreateButtonWithMessage({
			isOpen: Boolean(isExceededIssueTypesMessageOpen),
			setIsOpen: setIsExceededIssueTypesMessageOpen,
			testId: 'work-management-board.ui.board.column-create.is-exceeded-issue-types',
			label: formatMessage(messages.createStatusLabel),
			analyticsId: 'jwmColumnCreateExceededIssueTypes',
			MessageComponent: OpenProjectSettingsExceededIssueTypes,
		});
	}

	if (hasMultipleWorkflows) {
		return renderColumnCreateButtonWithMessage({
			isOpen: Boolean(isMultipleWorkflowsMessageOpen),
			setIsOpen: setIsMultipleWorkflowsMessageOpen,
			testId: 'work-management-board.ui.board.column-create.has-multiple-workflows-button',
			label: formatMessage(messages.createStatusLabel),
			analyticsId: 'jwmColumnCreateMultipleWorkflows',
			MessageComponent: OpenProjectSettingsMultipleWorkflows,
		});
	}

	const button = (
		<ModalEntryPointIconButtonTrigger
			entryPoint={createColumnEntryPoint}
			entryPointProps={createColumnModalProps}
			interactionName="jwmColumnCreate"
			appearance="subtle"
			testId="work-management-board.ui.board.column-create.create-column-button"
			icon={() => (
				<EditorAddIcon
					label=""
					spacing="spacious"
					color="currentColor"
					LEGACY_fallbackIcon={EditorAddIconLegacy}
				/>
			)}
			isDisabled={isWorkflowOperationInProgress}
			label={formatMessage(messages.createStatusLabel)}
		/>
	);
	return (
		<>
			<SurfaceWrapper>
				<Tooltip content={formatMessage(messages.createStatusLabel)}>{button}</Tooltip>
			</SurfaceWrapper>
		</>
	);
};

export default memo(ColumnCreate);

const renderColumnCreateButtonWithMessage = ({
	isOpen,
	setIsOpen,
	testId,
	label,
	analyticsId,
	MessageComponent,
}: {
	isOpen: boolean;
	setIsOpen: (state: boolean) => void;
	testId: string;
	label: string;
	analyticsId: string;
	MessageComponent: () => React.JSX.Element;
}) => (
	<InlineDialog
		content={
			<MessageWrapper>
				<MessageComponent />
			</MessageWrapper>
		}
		isOpen={isOpen}
		onClose={() => {
			setIsOpen(false);
		}}
	>
		<SurfaceWrapper>
			<Button
				appearance="subtle"
				testId={testId}
				aria-label={label}
				iconBefore={<EditorAddIcon label="" color={token('color.icon')} />}
				onClick={(_, analyticsEvent) => {
					setIsOpen(true);
					fireUIAnalytics(analyticsEvent, analyticsId);
				}}
			/>
		</SurfaceWrapper>
	</InlineDialog>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SurfaceWrapper = styled.div({
	borderRadius: token('border.radius', '3px'),
	backgroundColor: token('elevation.surface.sunken', N10),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageWrapper = styled.div({
	width: '200px',
});
