import React from 'react';
import MenuFallback from '@atlassian/jira-business-more-menu/src/common/ui/menu-fallback/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { MoreMenuContentProps } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyJWMBoardMoreMenuContent = lazy(
	() => import(/* webpackChunkName: "async-jwm-board-more-menu-content" */ './main'),
	{ ssr: false },
);

export const MoreMenuContent = (props: MoreMenuContentProps) => (
	<Placeholder name="jwm-board-more-menu-content" fallback={<MenuFallback />}>
		<LazyJWMBoardMoreMenuContent {...props} />
	</Placeholder>
);
