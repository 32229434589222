import { useCallback } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import {
	useExperienceAbort,
	useExperienceStart,
	useExperienceFail,
	useExperienceSuccess,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { useWorkflowsV2 } from '@atlassian/jira-business-workflows/src/services/workflow-v2';
import {
	mapStatusesToStatusUpdateObjects,
	mapWorkflowToWorkflowUpdateObject,
} from '@atlassian/jira-business-workflows/src/services/workflow-v2/utils';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useStartRenameOperation, useStopWorkflowOperationInProgress } from '../index';
import {
	getStatusActionFailedFlag,
	failStatusExperience,
	IssueTypeIdNotFoundError,
} from '../utils';
import messages from './messages';

const getCleanColumnName = (name: string): string => name.replace(/\s{2,}/g, ' ').trim();

export const useRenameStatus = (experienceDetails: ExperienceDetails) => {
	const startRenameOperation = useStartRenameOperation();
	const stopWorkflowOperationInProgress = useStopWorkflowOperationInProgress();

	const { fetchWorkflowsV2, validateAndUpdateWorkflowsV2 } = useWorkflowsV2();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();

	const startExperience = useExperienceStart(experienceDetails);
	const markExperienceSuccess = useExperienceSuccess(experienceDetails);
	const markExperienceFailed = useExperienceFail(experienceDetails);
	const abortExperience = useExperienceAbort(experienceDetails);

	const project = useProject();
	const { showFlag } = useFlagService();

	/**
	 * @returns undefined if it is valid, otherwise return string that will be used as error message
	 * this is a pattern used by atlaskit form field validation or inline edit
	 */
	const validateRenameStatus = useCallback(
		({
			oldName,
			newName,
			existingStatusNames,
		}: {
			oldName: string;
			newName: string;
			existingStatusNames: string[];
		}) => {
			const cleanNewName = getCleanColumnName(newName);
			const convertedNewName = cleanNewName.toUpperCase();

			if (convertedNewName === oldName.toUpperCase()) {
				return undefined;
			}

			if (!cleanNewName.length) {
				return formatMessage(messages.emptyColumnNameError);
			}

			if (existingStatusNames.some((statusName) => statusName.toUpperCase() === convertedNewName)) {
				return formatMessage(messages.statusAlreadyExists, {
					statusName: cleanNewName,
				});
			}
		},
		[formatMessage],
	);

	const renameStatus = useCallback(
		async ({
			oldName,
			statusId,
			newName,
			issueTypeIdNotSubtask,
		}: {
			oldName: string;
			statusId: number;
			newName: string;
			issueTypeIdNotSubtask: string | undefined;
		}) => {
			const cleanNewName = getCleanColumnName(newName);
			const convertedNewName = cleanNewName.toUpperCase();

			if (!cleanNewName.length || convertedNewName === oldName.toUpperCase()) {
				return;
			}

			startRenameOperation(String(statusId));
			startExperience();
			try {
				if (issueTypeIdNotSubtask == null) {
					throw new IssueTypeIdNotFoundError(
						'issueTypeIdNotSubtask is required but was not found.',
					);
				}
				// fetch workflow data from workflow API
				const workflowsData = await fetchWorkflowsV2({
					cloudId,
					projectAndIssueTypes: [
						{
							projectId: project.id.toString(),
							issueTypeId: issueTypeIdNotSubtask,
						},
					],
				});

				if (workflowsData) {
					// rename the status in place
					const toRenameStatus = workflowsData.statuses.find((s) => s.id === statusId.toString());

					if (toRenameStatus) {
						toRenameStatus.name = cleanNewName;
						await validateAndUpdateWorkflowsV2({
							cloudId,
							payload: {
								payload: {
									statuses: mapStatusesToStatusUpdateObjects(workflowsData.statuses),
									workflows: [mapWorkflowToWorkflowUpdateObject(workflowsData.workflows[0])],
								},
								validationOptions: { levels: ['ERROR'] },
							},
						});
						markExperienceSuccess();
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				showFlag(
					getStatusActionFailedFlag({
						messageTitle: messages.errorFlagTitleRenameColumn,
						action: 'rename',
						error,
						issueTypeIdNotSubtask,
						projectKey: project.key,
						statusCode: error?.response?.status,
					}),
				);

				failStatusExperience({
					markExperienceFailed,
					abortExperience,
					error,
					errorMessage: 'Failed to validate and update workflow',
				});

				throw error;
			} finally {
				stopWorkflowOperationInProgress();
			}
		},
		[
			startRenameOperation,
			startExperience,
			fetchWorkflowsV2,
			cloudId,
			project.id,
			project.key,
			validateAndUpdateWorkflowsV2,
			markExperienceSuccess,
			showFlag,
			markExperienceFailed,
			abortExperience,
			stopWorkflowOperationInProgress,
		],
	);

	return {
		renameStatus,
		validateRenameStatus,
	};
};
