import React from 'react';
import Placeholder from '@atlassian/jira-placeholder';
import { lazy } from '@atlassian/react-loosely-lazy';
import type ConfluenceViewModal from './main';
import type { ViewPageProps } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyConfluenceViewModal = lazy<typeof ConfluenceViewModal>(
	() =>
		import(
			/* webpackChunkName: "async-jwm-confluence-pages-confluence-view-modal-confluence-modal" */ './main'
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: ViewPageProps) => (
	<Placeholder name="view-modal">
		<LazyConfluenceViewModal {...props} />
	</Placeholder>
);
