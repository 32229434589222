import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { COLUMN_HEADER_HEIGHT } from '../../../../common/constants';
import RestrictedIcon from '../../../../common/ui/restricted-icon';
import { useIssueGroup } from '../../../../controllers/issues-by-group';
import messages from './messages';

const InvalidDropTargetMessage = () => {
	const { formatMessage } = useIntl();
	const { group } = useIssueGroup();

	let message: MessageDescriptor;

	const groupType = group.type;

	switch (groupType) {
		case GROUP_BY_ASSIGNEE:
			message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.unableToUpdateAssigneeMessageIssueTermRefresh
				: messages.unableToUpdateAssigneeMessage;
			break;
		case GROUP_BY_CATEGORY:
			message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.unableToUpdateCategoryMessageIssueTermRefresh
				: messages.unableToUpdateCategoryMessage;
			break;
		case GROUP_BY_PRIORITY:
			message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.unableToUpdatePriorityMessageIssueTermRefresh
				: messages.unableToUpdatePriorityMessage;
			break;
		case GROUP_BY_STATUS:
			message = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.transitionNotAvailableMessageIssueTermRefresh
				: messages.transitionNotAvailableMessage;
			break;
		default: {
			const exhaustiveCheck = groupType;
			throw new Error(`Unexpected group type: ${exhaustiveCheck}`);
		}
	}

	return (
		<Wrapper data-testid="work-management-board.ui.board.column.invalid-drop-target-message">
			<ContentWrapper>
				<RestrictedIcon />
				<Message>{formatMessage(message)}</Message>
			</ContentWrapper>
		</Wrapper>
	);
};

export default memo(InvalidDropTargetMessage);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	backgroundColor: token('color.background.danger', '#FFECEB'),
	borderRadius: `0 0 ${token('border.radius.200', '8px')} ${token('border.radius.200', '8px')}`,
	inset: '0',
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${COLUMN_HEADER_HEIGHT}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
	padding: `${token('space.400', '32px')} ${token('space.200', '16px')}`,
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Message = styled.p({
	margin: '0',
});
