import React from 'react';
import ProjectIcon from '@atlaskit/icon/core/folder-closed';
import ProjectIconLegacy from '@atlaskit/icon/glyph/folder';
import { PROJECT_PICKER_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { GetIconFunction } from '../../../icon/types';

export const getProjectIcons: GetIconFunction = (size) => ({
	[PROJECT_PICKER_CF_TYPE]: (
		<ProjectIcon
			color="currentColor"
			label=""
			LEGACY_size={size}
			LEGACY_fallbackIcon={ProjectIconLegacy}
		/>
	),
});
