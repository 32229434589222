import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type CardDetailConfluencePages from './main';
import type { Props } from './main';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCardDetailConfluencePages = lazyForPaint<typeof CardDetailConfluencePages>(
	() => import(/* webpackChunkName: "async-jwm-board-card-detail-confluence-pages" */ './main'),
	{ ssr: false },
);

const AsyncCardDetailConfluencePages = (props: Props) => (
	<ErrorBoundary
		id="async.jira-business.board.card-detail-confluence-pages"
		packageName="jira-business.board.card-detail-confluence-pages"
		teamName="jira-warepil"
	>
		<Placeholder name="jwm-board-card-detail-confluence-pages" fallback={<Skeleton />}>
			<LazyCardDetailConfluencePages {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export default AsyncCardDetailConfluencePages;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Skeleton = styled.div({
	backgroundColor: token('color.skeleton', '#091E420F'),
	borderRadius: token('border.radius', '4px'),
	height: '24px',
	width: '24px',
});
